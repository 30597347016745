@import '../../../scss/theme-bootstrap';
/* Email signup waitlist Popup */
.promo-waitlist-overlay {
  &.tiny-waitlist-overlay {
    width: 310px;
    margin: 40px 20px 20px;
    p {
      color: #1f1f1f;
    }
    form .field {
      margin-top: 12px;
      margin-bottom: 15px;
      label {
        color: #1f1f1f;
        font-weight: normal;
        text-transform: capitalize;
        font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
        margin-#{$rdirection}: 10px;
      }
      input {
        border: 1px solid #dbdcdd;
        height: 30px;
        width: 160px;
      }
    }
    .align-r input {
      background: none repeat scroll 0 0 #5db997;
      border: 0 none;
      color: #ffffff;
      display: inline-block;
      float: #{$rdirection};
      font-family: 'HelveticaNeueLTStd45Mideum', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
      font-size: 16px;
      font-weight: bold;
      height: 30px;
      padding: 0 13px;
      text-transform: uppercase;
      border-radius: 0;
    }
    ul.error {
      margin-top: 5px;
    }
    .error_messages li {
      background-clip: padding-box;
      background: none;
      border-radius: 3px;
      color: #ef6ea8;
      font-size: 12px;
      line-height: 1.3em;
      margin: 0px;
      padding: 0px;
    }
  }
}
